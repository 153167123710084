<template>

    <div class="move is-full-height is-overflow-y-scroll is-flex is-flex-direction-column is-justify-content-center is-overflow-y-scroll">
    
      <back-button class="ml-2"/> 
  
        <div class="is-flex is-flex-direction-row is-justify-content-center mb-2">
      


            <div class="file has-name is-boxed">
            <label class="file-label">
                 <input @change="handleInputChange" accept=".csv" class="file-input" type="file" name="csvfile" ref="fileInput">
                <span class="file-cta">
                <span class="file-icon">
                    <Icon :icon="['fas', 'upload']" classOverwrite="ml-1"/>
                </span>
                <span class="file-label">
                    Choose a file…
                </span>
                </span>
                <span v-if="filename" class="file-name">
                    {{ filename }}
                </span>
            </label>
            </div>        

        </div>
    
        
        <div class="is-z-3 is-full-width">
            <button :disabled="!filename" class="button m-2 mt-3 mb-4 has-background-purple-1 is-rounded has-text-light has-border-black" @click="uploadFile">IMPORT ITEM(S)</button>
            
            <button class="button m-2 mt-3 mb-4 has-background-light is-rounded has-text-dark has-border-black" @click="$router.push('/')">CANCEL</button>
        </div>

    </div>
    
  </template>
  
  <script>
  import BackButton from '@/components/Template/BackButton.vue';
  
  import { onMounted} from "@vue/runtime-core";
import { ref } from 'vue';
  import { useStore } from 'vuex';
  
  
  export default {
    name: 'ImportCSV',
    components: {
      BackButton
    },
    setup(){  
        const store = useStore();
        const fileInput = ref(null);
        const filename = ref(null)

        onMounted(()=>{
        })

        async function uploadFile(){
            let uploaded = await store.dispatch("uploadCSV",fileInput.value)
            if(uploaded.status==200){
                store.dispatch('triggerToast',{type: 'success',message:'successfully imported all items from csv file'})
            }
        }

        function handleInputChange(e){
            console.log(e.target.value);
            filename.value = e.target.value
        }

        return {
            uploadFile,
            fileInput,
            handleInputChange,
            filename
        }
    }
  }
  </script>